import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NoPage = () => {
    return (
      <>
      <div className="bg-red-900 relative overflow-hidden h-screen">
        <LazyLoadImage src="https://www.tailwind-kit.com/images/landscape/8.svg" className="absolute h-full w-full object-cover" />
        {/* <img src="https://www.tailwind-kit.com/images/landscape/8.svg" className="absolute h-full w-full object-cover" /> */}
        <div className="inset-0 bg-black opacity-25 absolute">
        </div>
        <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
          <div className="w-full font-mono flex flex-col items-center relative z-10">
            <h1 className="font-medium text-5xl text-center text-white leading-tight mt-4 tracking-widest">
              You're alone here
            </h1>
            <p className="font-extrabold text-8xl my-44 text-white animate-bounce">404</p>
            <Link to="/" className="inline-block py-3 px-8 text-sm text-white hover:text-red-900 uppercase font-semibold border-2 border-white hover:bg-white">Back to Home</Link>
          </div>
        </div>
      </div>
      </>
    );
  };
  
export default NoPage;