import React, { useState, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Home = () => {
    const [fromSelectModal, setfromSelectModal] = React.useState(false);
    const [scrollPosition, setScrollPosition] = useState('70');
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    function moveCircle() {
        const speed = 5
        const scrolltop = window.pageYOffset
        const scrollAndSpeed = scrolltop / speed
    
        // console.log(scrollAndSpeed.toString())
        setScrollPosition(scrollAndSpeed.toString())
    };
    useEffect(() => {
        window.addEventListener(
          'scroll',
          function () {
            requestAnimationFrame(moveCircle)
          },
          false
        )
    }, []);
    return (
        <>
            <section className='hero-section overflow-hidden lg:h-screen md:mb-10 px-4 flex items-center sticky md:pt-32 -mt-20 md:-mt-32 left-0 z-0'>
                {/* <img src="https://www.tailwind-kit.com/images/landscape/6.svg" className="absolute h-full w-full object-cover left-0 top-0" /> */}
                <LazyLoadImage src="images/hero-section-bg.png" className="absolute h-full w-full object-cover left-auto right-0 md:right-auto md:left-0 top-0 animate-pulse hidden md:block" />
                <LazyLoadImage src="images/hero-section-bg-mobile.png" className="absolute h-full w-full object-cover left-auto right-0 md:right-auto md:left-0 top-0 animate-pulse md:hidden" />
                <div className='container md:container h-full md:h-auto mx-auto px-4 py-16 md:py-0 relative z-10'>
                    {/* <LazyLoadImage src="images/ignite-hero-logo.png" className="hero-section-logo w-32 h-32 animate-pulse" /> */}
                    <div className='w-full md:w-1/2 md:mt-0 mt-96 md:pt-0 pt-10'>
                        <h1 className='leading-10 text-white sm:text-[#ff1d3f] text-2xl xl:text-4xl 2xl:text-6xl md:mt-0 mt-5 md:text-left text-center tracking-widest md:tracking-normal'>Secure & Scalable Blockchain Network</h1>
                        <div className="flex items-center md:justify-start justify-center py-10">
                            <div className="space-y-4 border-r border-gray-100 mr-4 pr-4 md:mr-6 md:pr-6">
                                <div className="relative bg-darkest-blue rounded-lg">
                                    <div className="flex items-center justify-center"><span className="text-gray-200 font-bold text-lg md:text-3xl">04</span></div>
                                    <div className="text-center"><span className="uppercase text-sm text-gray-200 font-bold tracking-4xl">days</span></div>
                                </div>
                            </div>
                            <div className="space-y-4 border-r border-gray-100 mr-4 pr-4 md:mr-6 md:pr-6">
                                <div className="relative bg-darkest-blue rounded-lg">
                                    <div className="flex items-center justify-center"><span className="text-gray-200 font-bold text-lg md:text-3xl">05</span></div>
                                    <div className="text-center"><span className="uppercase text-sm text-gray-200 font-bold tracking-4xl">Hours</span></div>
                                </div>
                            </div>
                            <div className="space-y-4 border-r border-gray-100 mr-4 pr-4 md:mr-6 md:pr-6">
                                <div className="relative bg-darkest-blue rounded-lg">
                                    <div className="flex items-center justify-center"><span className="text-gray-200 font-bold text-lg md:text-3xl">47</span></div>
                                    <div className="text-center"><span className="uppercase text-sm text-gray-200 font-bold tracking-4xl">Minutes</span></div>
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="relative bg-darkest-blue rounded-lg">
                                    <div className="flex items-center justify-center"><span className="text-gray-200 font-bold text-lg md:text-3xl">31</span></div>
                                    <div className="text-center"><span className="uppercase text-sm text-gray-200 font-bold tracking-4xl">Seconds</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='w-auto shadow-2xl flex items-center md:justify-start justify-center text-white text-sm font-semibold'>
                            <span className='py-2 px-3 border-r bg-[#7A092A] border-[#36082F] h-full'>Contract</span>
                            <a href="https://bscscan.com/address/0xd3592ecdbd198f32099ec29de734416dd8373f12" target="_blank" className='py-2 px-3 bg-[#7A092A] break-all'>0xd3....3F12</a>
                            <span className='py-2 px-2 bg-[#7A092A] h-full'><i class="las la-copy"></i></span>
                        </div>
                        <a className="md:inline-block table md:mx-0 mx-auto py-3 px-16 mt-10 text-sm text-white uppercase font-semibold border-2 shadow-xl border-white hover:border-[#7A092A] hover:bg-[#7A092A]" href="javascript:;">Buy Token</a>
                        {/* <a className="md:inline-block table md:mx-0 mx-auto py-3 px-16 mt-10 text-sm text-white uppercase font-semibold border-2 shadow-xl border-white hover:border-[#7A092A] hover:bg-[#7A092A]" href="javascript:;" onClick={() => setfromSelectModal(true)}>Buy Token</a> */}
                        {fromSelectModal ? (
                        <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-md px-3">
                                <div className="absolute top-0 left-0 w-full h-full" onClick={() => setfromSelectModal(false)}></div>
                                <div className="relative w-auto md:w-5xl my-6 mx-auto max-w-xl md:max-w-5xl">
                                    {/*content*/}
                                    <div className="w-full marker:border-0 rounded-none shadow-lg relative flex flex-col w-full bg-white bg-opacity-90 dark:bg-indigo-900 outline-none focus:outline-none">
                                        {/*header*/}
                                        <div className="flex items-start justify-between px-5 py-3 border-b border-[#7A092A] border-opacity-10 rounded-none">
                                            <div className="text-lg font-semibold leading-loose">IGNITE Private sale</div>
                                            <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-40 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => setfromSelectModal(false)}>
                                                <span className="bg-transparent text-black dark:text-white hover:text-[#36082F] h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                            </button>
                                        </div>
                                        {/*body*/}
                                        <div className="relative p-6 flex-auto">
                                            <div className="overflow-hidden">
                                                <div className="text-[#36082F] text-xl font-semibold text-center mb-5">Swap your BUSD with IGT Coin</div>
                                                <div className="form-control mb-5">
                                                    <div className="grid grid-cols-2 gap-4 mb-3">
                                                        <label className="label col-span-1 p-0">
                                                            <span className="label-text">Enter BNB Amount ( Availabe : )</span>
                                                        </label> 
                                                        <div className="label-text col-span-1 text-right"><span>Balance :</span> <span className="text-green-600">99.356</span></div>
                                                    </div>
                                                    <label className="input-group rounded-none">
                                                        <span className="rounded-none bg-[#7A092A] text-white">BUSD</span>
                                                        <input type="text" defaultValue="20.99" className="input input-bordered outline-none focus:shadow-none w-full border-[#7A092A]" /> 
                                                        <button class="btn btn-primary bg-[#7A092A] hover:bg-[#36082F] border-0 rounded-none">go</button>
                                                    </label>
                                                </div>
                                                <div className="text-sm text-center font-medium mb-5">You will recieve <span className="text-[#7A092A]">16.00</span> IGT</div>
                                                <ul className="list-none p-0">
                                                    <li className="flex items-center justify-between text-sm font-medium text-[#7A092A] mb-2 last:mb-0">
                                                        <div className="text-gray-500">1 IGT</div>
                                                        <div>2.00 BUSD</div>
                                                    </li>
                                                    <li className="flex items-center justify-between text-sm font-medium text-[#7A092A] mb-2 last:mb-0">
                                                        <div className="text-gray-500">Minimum</div>
                                                        <div>100 BUSD</div>
                                                    </li>
                                                    <li className="flex items-center justify-between text-sm font-medium text-[#7A092A] mb-2 last:mb-0">
                                                        <div className="text-gray-500">Maximum</div>
                                                        <div>30000 BUSD</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className="flex items-center flex-col justify-end px-6 py-4 border-t border-[#7A092A] border-opacity-10 rounded-none">
                                            <button
                                            className="bg-[#7A092A] hover:bg-[#36082F] text-white active:bg-[#36082F] font-bold uppercase text-sm w-full px-6 py-4 rounded-none shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 tracking-widest" type="button" onClick={() => setfromSelectModal(false)}>Close</button>
                                            {/* <button className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>Save Changes</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-75 fixed inset-0 z-40 bg-black backdrop-blur-xl"></div>
                        </>
                        ) : null}
                    </div>
                </div>
            </section>

            <section className="private-sale-section w-full block py-5 md:py-16 relative bg-[#0D0620] z-20 overflow-hidden" data-aos={"fade-up"}>
                <div className='md:container mx-auto px-4 relative z-10'>
                    <div className="flex items-center justify-center md:mb-20 mb-8" data-aos={"fade-up"}>
                        <div className="md:w-1/2">
                            <div className="text-center text-white md:text-6xl text-4xl font-extrabold uppercase -mb-5 text-opacity-10">Private Sale</div>
                            <h3 className="text-center text-[#ff1d3f] md:text-4xl text-2xl font-medium tracking-widest mb-5">IGT Coin Private Sale Map Out</h3>
                        </div>
                    </div>
                    <div className="mega-graph relative xl:w-8/12 flex mx-auto overflow-hidden">
                        <div className="head-bg" />
                        {/* finished actived */}
                        <div className="item flex-1">
                            <div className="graph-head">
                                <span className="num">$1</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" data-aos={"fade-left"} data-aos-duration={"1000"} style={{height: '15%', opacity: 1}}>
                                    <span className="bonus-info" style={{opacity: 1}}>Phase 1</span>
                                </div>
                            </div>
                        </div>
                        <div className="item flex-1">
                            <div className="graph-head">
                                <span className="num">$1.25</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" data-aos={"fade-left"} data-aos-duration={"900"} style={{height: '25%', opacity: 1}}>
                                    <span className="bonus-info" style={{opacity: 1}}>Phase 2</span>
                                </div>
                            </div>
                        </div>
                        <div className="item flex-1">
                            <div className="graph-head">
                                <span className="num">$1.50</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" data-aos={"fade-left"} data-aos-duration={"800"} style={{height: '35%', opacity: 1}}>
                                <span className="bonus-info" style={{opacity: 1}}>Phase 3</span>
                                </div>
                            </div>
                        </div>
                        <div className="item flex-1">
                            <div className="graph-head">
                                <span className="num">$1.75</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" data-aos={"fade-left"} data-aos-duration={"700"} style={{height: '45%', opacity: 1}}>
                                <span className="bonus-info" style={{opacity: 1}}>Phase 4</span>
                                </div>
                            </div>
                        </div>
                        <div className="item flex-1">
                            <div className="graph-head">
                                <span className="num">$2</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" data-aos={"fade-left"} data-aos-duration={"500"} style={{height: '55%', opacity: 1}}>
                                <span className="bonus-info" style={{opacity: 1}}>Phase 5</span>
                                </div>
                            </div>
                        </div>
                        <div className="item flex-1">
                            <div className="graph-head">
                                <span className="num">$2.25</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" data-aos={"fade-left"} data-aos-duration={"300"} style={{height: '65%', opacity: 1}}>
                                <span className="bonus-info" style={{opacity: 1}}>Phase 6</span>
                                </div>
                            </div>
                        </div>
                        <div className="item flex-1">
                            <div className="graph-head">
                                <span className="num">$2.50</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" data-aos={"fade-left"} data-aos-duration={"100"} style={{height: '75%', opacity: 1}}>
                                <span className="bonus-info" style={{opacity: 1}}>Phase 7</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="item flex-1 actived">
                            <div className="graph-head">
                                <span className="num">10%</span>
                            </div>
                            <div className="bar-wrap">
                                <div className="bar-inner" style={{height: '10%', opacity: 1}}>
                                <span className="bonus-info" style={{opacity: 1}}>3% Bonus</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="feature-section w-full block py-5 md:py-16 relative bg-[#0D0620] z-20 overflow-hidden">
                <div className='md:container mx-auto px-4 relative z-10'>
                    <div className="flex items-center justify-center md:mb-20 mb-8 w-full" data-aos={"fade-up"}>
                        <div className="lg:w-1/2">
                            <div className="text-center text-white md:text-6xl text-4xl font-extrabold uppercase -mb-5 text-opacity-10">Ignite Chain</div>
                            <h3 className="text-center text-[#ff1d3f] md:text-4xl text-2xl font-medium tracking-widest mb-5">How to Buy</h3>
                        </div>
                    </div>
                    <section className="section has-background-warning-light">
                        <div className="container">
                            <div className="grid grid-cols-12 items-center gap-5">
                                <div className="col-span-12 lg:col-span-6 mb-10 md:mb-0">
                                    <div className="md:pr-36">
                                        <div className="mb-8 flex items-start" data-aos={"fade-up"} data-aos-delay={"60"}>
                                            <span className="mr-4 p-4 flex items-center justify-center text-2xl text-white bg-[#ff1d3f] rounded-full w-18 h-18 animate-pulse">
                                                <i className="las la-angle-double-down"></i>
                                            </span>
                                            <div>
                                                <div className="mb-3 text-xl font-semibold text-white tracking-wider">Download & setup MetaMask or TrustWallet</div>
                                                <p className="text-sm tracking-wide text-justify leading-8 mb-0 text-white">Download MetaMask (a crypto wallet in the form of a browser extension) or TrustWallet (an app for your phone). After that you will have to add the Binance Smart Chain to your network-list. (Click here for a step-by-step tutorial)</p>
                                            </div>
                                        </div>
                                        <div className="mb-8 flex items-start" data-aos={"fade-up"} data-aos-delay={"80"}>
                                            <span className="mr-4 p-4 flex items-center justify-center text-2xl text-white bg-[#ff1d3f] rounded-full w-18 h-18 animate-pulse">
                                                <i className="las la-cat"></i>
                                            </span>
                                            <div>
                                                <div className="mb-3 text-xl font-semibold text-white tracking-wider">Buy and send BNB to MetaMask</div>
                                                <p className="text-sm tracking-wide text-justify leading-8 mb-0 text-white">Buy BNB on an exchange (i.e. Binance, Kraken, Coinbase etc.). Transfer the tokens to your MetaMask wallet address. BEP-20 addresses start with a "0x".</p>
                                            </div>
                                        </div>
                                        <div className="mb-8 flex items-start" data-aos={"fade-up"} data-aos-delay={"100"}>
                                            <span className="mr-4 p-4 flex items-center justify-center text-2xl text-white bg-[#ff1d3f] rounded-full w-18 h-18 animate-pulse">
                                                <i className="las la-exchange-alt"></i>
                                            </span>
                                            <div>
                                                <div className="mb-3 text-xl font-semibold text-white tracking-wider">Head on to PancakeSwap and swap for UDE</div>
                                                <p className="text-sm tracking-wide text-justify leading-8 mb-0 text-white">Click here to head on over to PancakeSwap or use this address to select UDEGO. Set the slippage tolerance to 0.5 – 1% (sometimes it may be a bit more, depending on how much demand there is).</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start" data-aos={"fade-up"} data-aos-delay={"120"}>
                                            <span className="mr-4 p-4 flex items-center justify-center text-2xl text-white bg-[#ff1d3f] rounded-full w-18 h-18 animate-pulse">
                                                <i className="las la-wallet"></i>
                                            </span>
                                            <div>
                                                <div className="mb-3 text-xl font-semibold text-white tracking-wider">View UDE and HODL</div>
                                                <p className="text-sm tracking-wide text-justify leading-8 mb-0 text-white">Swap BNB for UDE. Now you need to add UDE to your MetaMask (Tutorial here) or Trust Wallet to view your UDE. Lastly HODL!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 lg:col-span-4">
                                    <div className="mx-auto md:ml-auto relative md:h-[600px]">
                                        {/* <LazyLoadImage src="images/green-light-left.svg" style={{zIndex: 1}} className="absolute bottom-0 left-0" />
                                        <LazyLoadImage src="images/warning-light-small.svg" style={{zIndex: 1}} className="absolute top-0 right-0" /> */}
                                        <LazyLoadImage src="images/buy-and-sell.svg" style={{height: '100%'}} className="image w-full object-cover rounded-tl-3xl rounded-br-4xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>

            <section className="w-full block py-5 md:py-16 relative bg-[#0D0620] z-20 overflow-hidden">
                <div className='md:container mx-auto px-4 relative z-10'>
                    <div className="flex items-center justify-center md:mb-20 mb-8 w-full" data-aos={"fade-up"}>
                        <div className="lg:w-1/2">
                            <div className="text-center text-white md:text-6xl text-4xl font-extrabold uppercase -mb-5 text-opacity-10">Ignite Chain</div>
                            <h3 className="text-center text-[#ff1d3f] md:text-4xl text-2xl font-medium tracking-widest mb-5">The next generation BLOCKCHAIN for Decentralized Finance</h3>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 xl:grid-cols-10 gap-5 items-center">
                        <div className="lg:col-span-1 xl:col-span-4">
                            <div className="about-image-box 2xl:px-20 relative">
                                <LazyLoadImage src="images/about-wheel-light.png" className="object-cover mx-auto about-wheel brightness-50" />
                                <LazyLoadImage src="images/ignite-hero-logo.png" className="object-cover h-28 w-28 mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 animate-pulse" />
                            </div>
                        </div>
                        <div className="lg:col-span-1 xl:col-span-6 text-white text-sm text-justify leading-9" data-aos={"fade-in"}  data-aos-duration={"2000"}>
                            <p className="mb-3">Ignite Chain is powering the decentralized finance ecosystem not only for the individuals or end-users but for financial institutions & businesses that have cryptocurrency exposure. Defining itself as the next generation of blockchain, Ignite provides decentralized solutions to a number of on-chain partners ranging from small enterprises to big-tech & investment management funds.</p>
                            <p className="mb-3">Ignite uses a self-regulating and self-sustainable network model that enable pervasive of trustless economy & the ideology of pure decentralization.</p>
                            <p className="mb-3">Ignite blockchain network is created with the goal of forming a decentralized and distributed community that would be able to develop, test and launch new exciting blockchain technology powered financial products and applications.</p>
                            <p>With the aim to bring down heavy transaction fees and confirmation time, we are aiming for mass market adoption by providing lower costs yet ultra fast peer-to-peer network.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w-full block py-5 md:py-16 relative bg-[#0D0620] z-20 overflow-hidden">
                <div className='md:container mx-auto px-4 relative z-10'>
                    <div className="flex items-center justify-center md:mb-20 mb-8 w-full" data-aos={"fade-up"}>
                        <div className="lg:w-1/2">
                            <div className="text-center text-white md:text-6xl text-4xl font-extrabold uppercase -mb-5 text-opacity-10">Features</div>
                            <h3 className="text-center text-[#ff1d3f] md:text-4xl text-2xl font-medium tracking-widest mb-5">Why Ignite Chain</h3>
                            <p className="text-center text-lg text-white">Ignite Chain is built to enhance the on-chain decentralized experience and scale up the decentralized economy</p>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 xl:grid-cols-4 gap-5 overflow-hidden">
                        <div className="col-span-1" data-aos={"fade-right"}  data-aos-duration={"500"}>
                            <div className="border border-[#ff1d3f] border-opacity-60 p-6 text-center h-full">
                                <LazyLoadImage src="images/icon-fast.png" className="object-cover w-20 opacity-75 animate-pulse mx-auto delay-75" />
                                <div className="text-md md:text-2xl text-[#ff1d3f] tracking-widest font-bold my-5">Blazingly Fast</div>
                                <p className="text-gray-300 text-sm leading-6">Perform a transaction in no time with the network capable of processing as high as 1500 transactions per second.</p>
                            </div>
                        </div>
                        <div className="col-span-1" data-aos={"fade-right"}  data-aos-duration={"1000"}>
                            <div className="border border-[#ff1d3f] border-opacity-60 p-6 text-center h-full">
                                <LazyLoadImage src="images/icon-low-cost.png" className="object-cover w-20 opacity-75 animate-pulse mx-auto delay-100" />
                                <div className="text-md md:text-2xl text-[#ff1d3f] tracking-widest font-bold my-5">Lower Cost</div>
                                <p className="text-gray-300 text-sm leading-6">The cost of a transaction is almost zero yet in superfast speed and inexpensive deployment of smart contracts with developer bounty programs.</p>
                            </div>
                        </div>
                        <div className="col-span-1" data-aos={"fade-right"}  data-aos-duration={"1200"}>
                            <div className="border border-[#ff1d3f] border-opacity-60 p-6 text-center h-full">
                                <LazyLoadImage src="images/icons-interoprability.png" className="object-cover w-20 opacity-75 animate-pulse mx-auto delay-150" />
                                <div className="text-md md:text-2xl text-[#ff1d3f] tracking-widest font-bold my-5">Interoperability</div>
                                <p className="text-gray-300 text-sm leading-6">Ignite Chain is functionable with every other blockchain with an in-built smart bridge contract that allows cross-chain transaction of assets seamlessly.</p>
                            </div>
                        </div>
                        <div className="col-span-1" data-aos={"fade-right"}  data-aos-duration={"1500"}>
                            <div className="border border-[#ff1d3f] border-opacity-60 p-6 text-center h-full">
                                <LazyLoadImage src="images/icon-secure.png" className="object-cover w-20 opacity-75 animate-pulse mx-auto delay-1000" />
                                <div className="text-md md:text-2xl text-[#ff1d3f] tracking-widest font-bold my-5">Secured Architecture operations</div>
                                <p className="text-gray-300 text-sm leading-6">With the use of the custom protocol, Ignite Chain offers multiple security layers to protect the network from unnecessary entities and access.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="w-full block pt-5 md:pt-16 relative bg-[#0D0620] z-20 overflow-hidden bg-cover bg-center bg-no-repeat" style={{background: 'url(images/graph-background.png)'}}>
                <div className='md:container mx-auto px-4 relative z-10'>
                    <div className="flex items-center justify-center w-full" data-aos={"fade-up"} data-aos-delay={"50"}>
                        <div className="lg:w-1/2">
                            <div className="text-center text-white md:text-6xl text-4xl font-extrabold uppercase -mb-5 text-opacity-10">Ignite Chain</div>
                            <h3 className="text-center text-[#ff1d3f] md:text-4xl text-2xl font-medium tracking-widest mb-5">Tokenomics</h3>
                        </div>
                    </div>
                    <div className="w-full md:grid lg:grid-cols-1 xl:grid-cols-1 md:gap-5 items-center justify-center">
                        <div className="col-span-1 2xl:px-14" data-aos={"fade-right"} data-aos-duration={"600"}>
                            <LazyLoadImage src="images/graph.svg" className="object-cover mx-auto w-full md:w-1/2" />
                        </div>
                        {/* <div className="col-span-2">
                            <div className="flex flex-col gap-4" data-aos={"fade-right"}  data-aos-duration={"800"}>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '10%'}}>10%</div>
                                            <div className="text-sm text-white ml-4">Founder</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '5%'}}>5%</div>
                                            <div className="text-sm text-white ml-4">Advisory</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '5%'}}>5%</div>
                                            <div className="text-sm text-white ml-4">Development</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '10%'}}>10%</div>
                                            <div className="text-sm text-white ml-4">Marketing</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '10%'}}>10%</div>
                                            <div className="text-sm text-white ml-4">Emergency Fund</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '10%'}}>10%</div>
                                            <div className="text-sm text-white ml-4">CEX & DEX Liquadity</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '10%'}}>10%</div>
                                            <div className="text-sm text-white ml-4">Private Sale</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '10%'}}>10%</div>
                                            <div className="text-sm text-white ml-4">Staking & Lending</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full shadow block">
                                        <div className="w-full h-6 bg-opacity-5 rounded-full mt-3 flex items-center">
                                            <div className="h-full text-center text-xs text-white bg-gradient-to-r from-[#7d1f73] to-[#ec5c3d] rounded-full flex items-center justify-center" style={{width: '30%'}}>30%</div>
                                            <div className="text-sm text-white ml-4">Public Sale</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="relative pt-16 md:pt-32 bg-[#0D0620] z-20 overflow-hidden" id="walletContainer">
                <div className="flex items-center justify-center mb-8 w-full" data-aos={"fade-up"}>
                    <div className="w-full lg:w-1/2">
                        <div className="text-center text-white md:text-6xl text-4xl font-extrabold uppercase -mb-5 text-opacity-10">Ignite Ecosystem</div>
                        <h3 className="text-center text-[#ff1d3f] md:text-2xl text-lg font-medium tracking-widest md:mb-5">We are building a Thriving Ecosystem on top of Ignite Chain to maximize the power of Decentralization.</h3>
                    </div>
                </div>
                {/* <img className="absolute z-10 top-0 md:top-1/2 left-0 mt-20 md:mt-0 transform md:-translate-y-1/2 -translate-x-1/2 w-32 md:w-52 lg:w-80 h-32 md:h-52 lg:h-80 rounded-full" src="images/placeholder1.png" alt="" /> */}
                {/* <img className="absolute z-10 top-0 md:top-1/2 right-0 mt-20 md:mt-0 transform md:-translate-y-1/2 translate-x-1/2 w-32 md:w-52 lg:w-80 h-32 md:h-52 lg:h-80 rounded-full" src="images/placeholder2.png" alt="" /> */}
                <div className="relative container mx-auto px-4">
                    <LazyLoadImage src="images/right.svg" className="absolute z-10 md:top-1/2 transform md:-translate-y-1/2 -translate-x-1/2 h-32 md:h-2/5 hidden md:block square square-1" style={{ left: `${scrollPosition-1000}px` }} />
                    <LazyLoadImage src="images/left.svg" className="absolute z-10 md:top-1/2 transform md:-translate-y-1/2 translate-x-1/2 h-32 md:h-2/5 hidden md:block square square-2" style={{ right: `${scrollPosition-1000}px` }} />
                    {/* <img className="absolute top-0 md:top-1/2 left-1/2 transform -translate-y-1/2 md:-mt-32" src="images/shadow-blue-full.svg" alt="" /> */}
                    {/* <img className="absolute top-0 md:top-1/2 left-1/2 transform -translate-y-1/2 mr-64 w-full" src="images/shadow-light-7.svg" alt="" /> */}
                    {/* <img className="hidden md:block absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-52 lg:w-96 h-52 lg:h-96 rounded-full" src="images/article1.jpeg" alt="" /> */}
                    <LazyLoadImage src="images/fire-ignite-black.png" className="md:block md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-y-1/2 md:-translate-x-1/2 animate-pulse md:w-1/4 mx-auto mb-6 md:mb-0" />
                    {/* <video autoPlay muted loop className='hidden md:block absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full lg:w-full lg:h-full rounded-full'>
                        <source src="fire-animation.mp4" type="video/mp4" />
                    </video> */}
                    <div className="relative flex flex-wrap -mx-4">
                        <div className="w-full md:w-1/2 px-4 mb-6 md:mb-10">
                            <div className="max-w-xs mx-auto text-center" data-aos={"fade-up"} data-aos-delay={"50"}>
                                {/* <div className="relative flex mx-auto mb-4 items-center justify-center h-32 w-32">
                                    <LazyLoadImage src="images/ellipse.svg" className="absolute top-0 left-0 w-full h-full" />
                                    <span className="text-white">1</span>
                                </div> */}
                                <div className="mb-2 md:mb-4 text-2xl text-[#ff1d3f] font-bold">Ignite Wallet</div>
                                <p className="text-sm tracking-wide leading-7 mb-0 text-white">A native wallet for Ignite Chain to store cryptocurrency assets and non-fungible tokens.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-6 md:mb-10">
                            <div className="max-w-xs mx-auto text-center" data-aos={"fade-up"} data-aos-delay={"100"}>
                                {/* <div className="relative flex mx-auto mb-4 items-center justify-center h-32 w-32">
                                    <LazyLoadImage src="images/ellipse.svg" className="absolute top-0 left-0 w-full h-full" />
                                    <span className="text-white">2</span>
                                </div> */}
                                <div className="mb-2 md:mb-4 text-2xl text-[#ff1d3f] font-bold">Ignite Explorer</div>
                                <p className="text-sm tracking-wide leading-7 mb-0 text-white">The tool for exploring data and activity insights on Ignite Chain such as blocks, transactions, addresses, contracts, etc.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-6 md:mb-10">
                            <div className="max-w-xs mx-auto text-center" data-aos={"fade-up"} data-aos-delay={"150"}>
                                {/* <div className="relative flex mx-auto mb-4 items-center justify-center h-32 w-32">
                                    <LazyLoadImage src="images/ellipse.svg" className="absolute top-0 left-0 w-full h-full" />
                                    <span className="text-white">3</span>
                                </div> */}
                                <div className="mb-2 md:mb-4 text-2xl text-[#ff1d3f] font-bold">IgnitEX</div>
                                <p className="text-sm tracking-wide leading-7 mb-0 text-white">The native crypto exchange to trade assets live on Ignite Chain with popular cryptocurrencies such as bitcoin, ether, dogecoin, Solana, and many others.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-6 md:mb-10">
                            <div className="max-w-xs mx-auto text-center" data-aos={"fade-up"} data-aos-delay={"200"}>
                                {/* <div className="relative flex mx-auto mb-4 items-center justify-center h-32 w-32">
                                    <LazyLoadImage src="images/ellipse.svg" className="absolute top-0 left-0 w-full h-full" />
                                    <span className="text-white">4</span>
                                </div> */}
                                <div className="mb-2 md:mb-4 text-2xl text-[#ff1d3f] font-bold">IgniteDEX</div>
                                <p className="text-sm tracking-wide leading-7 mb-0 text-white">IgniteDEX is an automated market maker and decentralized exchange built on top of Ignite Chain.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                            <div className="max-w-xs mx-auto text-center" data-aos={"fade-up"} data-aos-delay={"250"}>
                                {/* <div className="relative flex mx-auto mb-4 items-center justify-center h-32 w-32">
                                    <LazyLoadImage src="images/ellipse.svg" className="absolute top-0 left-0 w-full h-full" />
                                    <span className="text-white">3</span>
                                </div> */}
                                <div className="mb-2 md:mb-4 text-2xl text-[#ff1d3f] font-bold">Ignite NFT Market</div>
                                <p className="text-sm tracking-wide leading-7 mb-0 text-white">Ignite Chain has built a marketplace for buying, selling, and minting non-fungible tokens (NFTs).</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4">
                            <div className="max-w-xs mx-auto text-center" data-aos={"fade-up"} data-aos-delay={"300"}>
                                {/* <div className="relative flex mx-auto mb-4 items-center justify-center h-32 w-32">
                                    <LazyLoadImage src="images/ellipse.svg" className="absolute top-0 left-0 w-full h-full" />
                                    <span className="text-white">4</span>
                                </div> */}
                                <div className="mb-2 md:mb-4 text-2xl text-[#ff1d3f] font-bold">Ignite DevNet</div>
                                <p className="text-sm tracking-wide leading-7 mb-0 text-white">It is a community platform for Ignite Chain including validators, developers, and contributors.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative overflow-hidden">
                <section className="py-20 bg-blueGray-50">
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap items-center justify-center max-w-2xl lg:max-w-full mb-12">
                            <div className="w-full mb-4 lg:mb-0">
                                <div className="text-center text-white md:text-4xl text-xl font-extrabold uppercase -mb-2 text-opacity-10">Ignite Chain Custom Protocols</div>
                                <h2 className="text-center text-3xl md:text-4xl text-[#ff1d3f] font-heading tracking-wider">
                                    <span>We Collaborate Only With <span className="text-white block">The Best</span></span>
                                </h2>
                            </div>
                        </div>
                        <div className="flex flex-wrap jus -mx-3 -mb-6 text-center">
                            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                                <div className="p-12 h-full bg-white hover:bg-red-600 bg-opacity-5 hover:bg-opacity-5 shadow rounded transition-all ease-in-out duration-300">
                                    {/* <div className="flex w-12 h-12 mx-auto items-center justify-center text-white font-bold font-heading bg-red-600 rounded-full">1</div> */}
                                    {/* <img className="h-48 mx-auto my-4" src="images/work-tv.png" alt="" /> */}
                                    <LazyLoadImage src="images/game.png" className="h-36 mx-auto my-4" />
                                    <div className="mb-2 text-2xl font-semibold font-heading text-white tracking-wide mb-5 md:my-6">IGT GameX</div>
                                    <p className="text-sm text-white leading-7">GameX protocol lowers the use of guest computing power and instead it uses cloud-hosted graphical power to provide smoother user interfaces.</p>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                                <div className="p-12 h-full bg-white hover:bg-red-600 bg-opacity-5 hover:bg-opacity-5 shadow rounded transition-all ease-in-out duration-300">
                                    {/* <div className="flex w-12 h-12 mx-auto items-center justify-center text-white font-bold font-heading bg-red-600 rounded-full">2</div> */}
                                    {/* <img className="h-48 mx-auto my-4" src="images/people-watching.png" alt="" /> */}
                                    <LazyLoadImage src="images/reward.png" className="h-36 mx-auto my-4" />
                                    <div className="mb-2 text-2xl font-semibold font-heading text-white tracking-wide mb-5 md:my-6">IGT Reward</div>
                                    <p className="text-sm text-white leading-7">Reward protocol is called automatically when users & community members are enabled to be rewarded with predefined benefits and bounties.</p>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/3 px-3 mb-6">
                                <div className="p-12 h-full bg-white hover:bg-red-600 bg-opacity-5 hover:bg-opacity-5 shadow rounded transition-all ease-in-out duration-300">
                                    {/* <div className="flex w-12 h-12 mx-auto items-center justify-center text-white font-bold font-heading bg-red-600 rounded-full">3</div> */}
                                    {/* <img className="h-48 mx-auto my-4" src="images/financial-report.png" alt="" /> */}
                                    <LazyLoadImage src="images/privacy.png" className="h-36 mx-auto my-4" />
                                    <div className="mb-2 text-2xl font-semibold font-heading text-white tracking-wide mb-5 md:my-6">IGT Privacy</div>
                                    <p className="text-sm text-white leading-7">Privacy protocol is a precaution and risk-mitigating function which calls upon while the user is interacting with unauthorized entities or malicious actionable parties.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className="overflow-hidden">
                <div className="flex w-full lg:h-full">
                    <div className="hidden lg:block lg:relative lg:w-1/3 bg-[#d10524] rounded-tr-full rounded-br-full">
                        <div className="absolute top-1/2 right-0 transform -translate-y-1/2 md:mr-16 flex items-center">
                            {/* <img className="lg:max-h-screen" src="images/hardworking-man.png" alt="" /> */}
                            <LazyLoadImage src="images/faq.svg" className="lg:max-h-screen w-screen" />
                        </div>
                    </div>
                    <div className="flex-1 lg:pl-32 py-10 lg:py-16">
                        <div className="max-w-4xl px-4 mx-auto lg:ml-0">
                            <div className="text-left text-white md:text-5xl text-3xl font-extrabold uppercase -mb-3 text-opacity-10">IGNITE - Decentralized Solution</div>
                            <h3 className="mb-8 text-4xl text-[#d10524] font-heading">Frequently Asked Questions</h3>
                            <ul className="space-y-8">
                                <li>
                                    <div tabindex="0" class="collapse bg-base-100 bg-opacity-10 collapse-plus border-b border-white border-opacity-10">
                                        <div class="collapse-title text-sm text-white font-medium tracking-wide leading-8 px-6">How to participate in Ignite Coin Private Sale?</div>
                                        <div class="collapse-content text-white p-0 border-t border-white border-opacity-10"> 
                                            <div className="collapse-body p-4 text-xs">
                                                <p>Click on ‘Join Private Sale,’ connect your wallet and enter the amount.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div tabindex="0" class="collapse bg-base-100 bg-opacity-10 collapse-plus border-b border-white border-opacity-10">
                                        <div class="collapse-title text-sm text-white font-medium tracking-wide leading-8 px-6">Which cryptocurrency is acceptable in Private Sale?</div>
                                        <div class="collapse-content text-white p-0 border-t border-white border-opacity-10"> 
                                            <div className="collapse-body p-4 text-xs">
                                                <p>You can buy IGT Coins with BUSD & BLVR</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div tabindex="0" class="collapse bg-base-100 bg-opacity-10 collapse-plus border-b border-white border-opacity-10">
                                        <div class="collapse-title text-sm text-white font-medium tracking-wide leading-8 px-6">What will be the price of IGT Coin?</div>
                                        <div class="collapse-content text-white p-0 border-t border-white border-opacity-10"> 
                                            <div className="collapse-body p-4 text-xs">
                                                <p>Price for IGT Coin will depend on the phase. There are total of 7 phases and each of them have different price ranging from $1.00 to $2.50.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div tabindex="0" class="collapse bg-base-100 bg-opacity-10 collapse-plus border-b border-white border-opacity-10">
                                        <div class="collapse-title text-sm text-white font-medium tracking-wide leading-8 px-6">When will Public Sale start?</div>
                                        <div class="collapse-content text-white p-0 border-t border-white border-opacity-10"> 
                                            <div className="collapse-body p-4 text-xs">
                                                <p>Public Sale will start after Private Round is completed with additional 15 Million IGT Coin.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div tabindex="0" class="collapse bg-base-100 bg-opacity-10 collapse-plus border-b border-white border-opacity-10">
                                        <div class="collapse-title text-sm text-white font-medium tracking-wide leading-8 px-6">What is the total supply for IGT Coin?</div>
                                        <div class="collapse-content text-white p-0 border-t border-white border-opacity-10"> 
                                            <div className="collapse-body p-4 text-xs">
                                                <p>Total supply for IGT coin is 50 Million.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div tabindex="0" class="collapse bg-base-100 bg-opacity-10 collapse-plus border-b border-white border-opacity-10">
                                        <div class="collapse-title text-sm text-white font-medium tracking-wide leading-8 px-6">Where IGT coins will be stored?</div>
                                        <div class="collapse-content text-white p-0 border-t border-white border-opacity-10"> 
                                            <div className="collapse-body p-4 text-xs">
                                                <p>IGT coins will be stored in user addresses on Ignite Wallet or Metamask.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div tabindex="0" class="collapse bg-base-100 bg-opacity-10 collapse-plus border-b border-white border-opacity-10">
                                        <div class="collapse-title text-sm text-white font-medium tracking-wide leading-8 px-6">What is the goal for Ignite Chain?</div>
                                        <div class="collapse-content text-white p-0 border-t border-white border-opacity-10"> 
                                            <div className="collapse-body p-4 text-xs">
                                                <p>Ignite Chain is aiming to empower the global finance with decentralized solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative py-10 2xl:py-40 overflow-hidden">
                <div className="container px-4 mx-auto">
                    <div className="mb-14 text-center">
                        <div className="text-center text-white md:text-5xl text-3xl font-extrabold uppercase -mb-5 text-opacity-10">In the News</div>
                        <h2 className="mt-8 text-5xl font-heading text-[#ff1d3f]">Latest blog</h2>
                    </div>
                    <div className="flex flex-wrap justify-center">
                        <div className="relative w-full lg:w-1/3 lg:p-6 mb-4">
                            {/* <img className="hidden lg:block absolute top-0 left-0 -ml-40 mt-24" src="images/left-line.svg" alt="" /> */}
                            <LazyLoadImage src="images/left-line.svg" className="hidden lg:block absolute top-0 left-0 -ml-40 mt-24" />
                            <div className="relative z-10 bg-white bg-opacity-5 rounded-lg">
                                <div className="relative mb-8 h-auto lg:h-52">
                                {/* <img className="w-full h-full rounded-lg object-cover object-top" src="https://images.pexels.com/photos/6822288/pexels-photo-6822288.jpeg?q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=450&w=940" alt="" /> */}
                                <LazyLoadImage src="https://ignite.info/assets/images/blog/ignite-chain-introduction.jpg" className="w-full h-full rounded-lg object-cover object-top" />
                                <div className="absolute bottom-0 right-4 ml-8 mb-6 px-3 pb-3 pt-1 inline-block bg-white rounded-b-2xl border-t-4 border-red-700">
                                    <p className="text-xl font-bold">17</p>
                                    <p className="text-xs uppercase text-gray-300">Jan</p>
                                    {/* <p className="text-xs uppercase text-gray-500">2022</p> */}
                                </div>
                                </div>
                                <div className="px-8 pb-8">
                                    <a className="inline-block pt-4 text-lg text-white hover:text-red-600 font-bold border-t border-gray-400" href="https://ignitechain.medium.com/introduction-to-ignite-chain-the-next-generation-blockchain-for-defi-c6477c529cca" target={'_blank'}>Introduction Of Ignite Smart Chain</a>
                                </div>
                            </div>
                        </div>
                        <div className="relative w-full lg:w-1/3 lg:p-6 mb-4">
                            {/* <img className="hidden lg:block absolute top-0 left-0 -ml-14 mt-24" src="images/right-line.svg" alt="" /> */}
                            <LazyLoadImage src="images/right-line.svg" className="hidden lg:block absolute top-0 left-0 -ml-14 mt-24" />
                            <div className="relative z-10 lg:mt-24 bg-white bg-opacity-5 rounded-lg">
                                <div className="relative mb-8 h-auto lg:h-52">
                                {/* <img className="w-full h-full rounded-lg object-cover" src="https://images.pexels.com/photos/5325768/pexels-photo-5325768.jpeg?q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=450&w=940" alt="" /> */}
                                <LazyLoadImage src="https://miro.medium.com/max/1400/1*LavPEv0GPGyaZ8D5TrggXg.jpeg" className="w-full h-full rounded-lg object-cover" />
                                <div className="absolute bottom-0 right-4 ml-8 mb-6 px-3 pb-3 pt-1 inline-block bg-white rounded-b-2xl border-t-4 border-red-700">
                                    <p className="text-xl font-bold">17</p>
                                    <p className="text-xs uppercase text-gray-300">Jan</p>
                                </div>
                                </div>
                                <div className="px-8 pb-8">
                                    <a className="inline-block pt-4 text-lg text-white hover:text-red-600 font-bold border-t border-gray-400" href="https://ignitechain.medium.com/how-to-connect-metamask-wallet-to-ignite-chain-network-17964af74474" target={'_blank'}>How to connect Metamask wallet to Ignite Chain Network</a>
                                </div>
                            </div>
                        </div>
                        <div className="relative w-full lg:w-1/3 lg:p-6 mb-4">
                            {/* <img className="hidden lg:block absolute top-0 right-0 mr-64 mt-24" src="images/left-line.svg" alt="" /> */}
                            <LazyLoadImage src="images/left-line.svg" className="hidden lg:block absolute top-0 right-0 mr-64 mt-24" />
                            <div className="relative z-10 bg-white bg-opacity-5 rounded-lg">
                                <div className="relative mb-8 h-auto lg:h-52">
                                {/* <img className="mb-8 w-full h-52 rounded-lg object-cover" src="https://images.pexels.com/photos/5325708/pexels-photo-5325708.jpeg?q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=450&w=940" alt="" /> */}
                                <LazyLoadImage src="https://image.binance.vision/uploads-original/fee279af050e4355a27f91ac5a911389.png" className="mb-8 w-full mb-8 w-full h-auto lg:h-52 rounded-lg object-cover rounded-lg object-cover" />
                                <div className="absolute bottom-0 right-4 ml-8 mb-6 px-3 pb-3 pt-1 inline-block bg-white rounded-b-2xl border-t-4 border-red-700">
                                    <p className="text-xl font-bold">25</p>
                                    <p className="text-xs uppercase text-gray-300">Oct</p>
                                </div>
                                </div>
                                <div className="px-8 pb-8">
                                    <a className="inline-block pt-4 text-lg text-white hover:text-red-600 font-bold border-t border-gray-400" href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target={'_blank'}>Connecting MetaMask to Binance Smart Chain</a>
                                </div>
                            </div>
                            {/* <img className="hidden lg:block absolute top-0 right-0 -mr-32 mt-24" src="images/right-line.svg" alt="" /> */}
                            <LazyLoadImage src="images/right-line.svg" className="hidden lg:block absolute top-0 right-0 -mr-32 mt-24" />
                        </div>
                        <div className="relative w-full lg:w-1/3 lg:p-6 mb-4">
                            {/* <img className="hidden lg:block absolute top-0 left-0 -ml-40 mt-24" src="images/left-line.svg" alt="" /> */}
                            <LazyLoadImage src="images/left-line.svg" className="hidden lg:block absolute top-0 left-0 -ml-40 mt-24" />
                            <div className="relative z-10 bg-white bg-opacity-5 rounded-lg">
                                <div className="relative mb-8 h-auto lg:h-52">
                                {/* <img className="w-full h-full rounded-lg object-cover object-top" src="https://images.pexels.com/photos/6822288/pexels-photo-6822288.jpeg?q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=450&w=940" alt="" /> */}
                                <LazyLoadImage src="https://miro.medium.com/max/1400/1*A64d4vUsz3sw8Ccc_b41IA.jpeg" className="w-full h-full rounded-lg object-cover object-top" />
                                <div className="absolute bottom-0 right-4 ml-8 mb-6 px-3 pb-3 pt-1 inline-block bg-white rounded-b-2xl border-t-4 border-red-700">
                                    <p className="text-xl font-bold">16</p>
                                    <p className="text-xs uppercase text-gray-300">Feb</p>
                                </div>
                                </div>
                                <div className="px-8 pb-8">
                                    <a className="inline-block pt-4 text-lg text-white hover:text-red-600 font-bold border-t border-gray-400" href="https://ignitechain.medium.com/the-ignite-community-private-sale-is-now-live-fc3031a1bd4f" target={'_blank'}>The Ignite Community Private Sale is Now Live</a>
                                    </div>
                            </div>
                        </div>
                        <div className="relative w-full lg:w-1/3 lg:p-6 mb-4">
                            {/* <img className="hidden lg:block absolute top-0 right-0 mr-64 mt-24" src="images/left-line.svg" alt="" /> */}
                            <LazyLoadImage src="images/left-line.svg" className="hidden lg:block absolute top-0 right-0 mr-64 mt-24" />
                            <div className="relative z-10 bg-white bg-opacity-5 rounded-lg">
                                <div className="relative mb-8 h-auto lg:h-52">
                                {/* <img className="mb-8 w-full h-52 rounded-lg object-cover" src="https://images.pexels.com/photos/5325708/pexels-photo-5325708.jpeg?q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=450&w=940" alt="" /> */}
                                <LazyLoadImage src="https://miro.medium.com/max/1400/1*XSVh4r-33eYSO00HIPla4A.png" className="mb-8 w-full h-auto lg:h-52 rounded-lg object-cover" />
                                <div className="absolute bottom-0 right-4 ml-8 mb-6 px-3 pb-3 pt-1 inline-block bg-white rounded-b-2xl border-t-4 border-red-700">
                                    <p className="text-xl font-bold">17</p>
                                    <p className="text-xs uppercase text-gray-300">Feb</p>
                                </div>
                                </div>
                                <div className="px-8 pb-8">
                                    <a className="inline-block pt-4 text-lg text-white hover:text-red-600 font-bold border-t border-gray-400" href="https://ignitechain.medium.com/how-to-participate-in-ignite-coin-private-sale-f9e69750b7d5" target={'_blank'}>How to participate in Ignite Coin Private Sale?</a>
                                </div>
                            </div>
                            {/* <img className="hidden lg:block absolute top-0 right-0 -mr-32 mt-24" src="images/right-line.svg" alt="" /> */}
                            <LazyLoadImage src="images/right-line.svg" className="hidden lg:block absolute top-0 right-0 -mr-32 mt-24" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-8 md:pb-24 overflow-hidden">
                <div className="container px-4 mx-auto">
                    <div className="mb-14 text-center">
                        <div className="text-center text-white md:text-5xl text-3xl font-extrabold uppercase -mb-5 text-opacity-10">Ignite</div>
                        <h2 className="mt-8 text-4xl md:text-5xl font-heading text-[#ff1d3f]">Decentralized</h2>
                    </div>
                    <div className="flex flex-wrap lg:items-center justify-center -mx-4">
                        <div className="w-full md:w-4/12 px-4">
                            <div className="relative max-w-max">
                                {/* <img className="absolute z-10 -left-8 -top-8 w-28 md:w-auto text-yellow-400" src="images/circle3-yellow.svg" alt="" />
                                <img className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto text-blue-500" src="images/dots3-blue.svg" alt="" /> */}
                                <img src="images/decentralized.png" className="object-cover max-w-full" alt="" />
                            </div>
                        </div>
                        <div className="w-full md:w-6/12 px-4 mb-0">
                            <div className="flex flex-wrap p-8 text-center md:text-left hover:bg-white hover:bg-opacity-10 rounded-md hover:shadow-xl transition duration-200">
                                <div className="w-full md:w-auto mb-6 md:mb-0 md:pr-6">
                                    <div className="inline-flex h-14 w-14 mx-auto items-center justify-center text-white bg-[#ff1d3f] rounded-lg">
                                        <svg width={21} height={21} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 18.63H5C4.20435 18.63 3.44129 18.3139 2.87868 17.7513C2.31607 17.1887 2 16.4257 2 15.63V7.63C2 7.36479 1.89464 7.11043 1.70711 6.9229C1.51957 6.73536 1.26522 6.63 1 6.63C0.734784 6.63 0.48043 6.73536 0.292893 6.9229C0.105357 7.11043 0 7.36479 0 7.63L0 15.63C0 16.9561 0.526784 18.2279 1.46447 19.1655C2.40215 20.1032 3.67392 20.63 5 20.63H17C17.2652 20.63 17.5196 20.5246 17.7071 20.3371C17.8946 20.1496 18 19.8952 18 19.63C18 19.3648 17.8946 19.1104 17.7071 18.9229C17.5196 18.7354 17.2652 18.63 17 18.63ZM21 0.630005H7C6.20435 0.630005 5.44129 0.946075 4.87868 1.50868C4.31607 2.07129 4 2.83436 4 3.63V13.63C4 14.4257 4.31607 15.1887 4.87868 15.7513C5.44129 16.3139 6.20435 16.63 7 16.63H21C21.7956 16.63 22.5587 16.3139 23.1213 15.7513C23.6839 15.1887 24 14.4257 24 13.63V3.63C24 2.83436 23.6839 2.07129 23.1213 1.50868C22.5587 0.946075 21.7956 0.630005 21 0.630005ZM20.59 2.63L14.71 8.51C14.617 8.60373 14.5064 8.67813 14.3846 8.7289C14.2627 8.77966 14.132 8.8058 14 8.8058C13.868 8.8058 13.7373 8.77966 13.6154 8.7289C13.4936 8.67813 13.383 8.60373 13.29 8.51L7.41 2.63H20.59ZM22 13.63C22 13.8952 21.8946 14.1496 21.7071 14.3371C21.5196 14.5246 21.2652 14.63 21 14.63H7C6.73478 14.63 6.48043 14.5246 6.29289 14.3371C6.10536 14.1496 6 13.8952 6 13.63V4L11.88 9.88C12.4425 10.4418 13.205 10.7574 14 10.7574C14.795 10.7574 15.5575 10.4418 16.12 9.88L22 4V13.63Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="w-full md:flex-1">
                                    <div className="mb-4 text-xl md:text-2xl leading-tight text-white font-bold tracking-wider">Ignite Community</div>
                                    <p className="text-white text-sm leading-7 font-medium">There's something for everybody. Follow along, chat on Telegram, or read up on what we’re doing.</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap p-8 text-center md:text-left hover:bg-white hover:bg-opacity-10 rounded-md hover:shadow-xl transition duration-200">
                                <div className="w-full md:w-auto mb-6 md:mb-0 md:pr-6">
                                    <div className="inline-flex h-14 w-14 mx-auto items-center justify-center text-white bg-[#ff1d3f] rounded-lg">
                                        <svg width={21} height={21} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 18H9.24C9.37161 18.0008 9.50207 17.9755 9.62391 17.9258C9.74574 17.876 9.85656 17.8027 9.95 17.71L16.87 10.78L19.71 8C19.8037 7.90704 19.8781 7.79644 19.9289 7.67458C19.9797 7.55272 20.0058 7.42201 20.0058 7.29C20.0058 7.15799 19.9797 7.02728 19.9289 6.90542C19.8781 6.78356 19.8037 6.67296 19.71 6.58L15.47 2.29C15.377 2.19627 15.2664 2.12188 15.1446 2.07111C15.0227 2.02034 14.892 1.9942 14.76 1.9942C14.628 1.9942 14.4973 2.02034 14.3754 2.07111C14.2536 2.12188 14.143 2.19627 14.05 2.29L11.23 5.12L4.29 12.05C4.19732 12.1434 4.12399 12.2543 4.07423 12.3761C4.02446 12.4979 3.99924 12.6284 4 12.76V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18ZM14.76 4.41L17.59 7.24L16.17 8.66L13.34 5.83L14.76 4.41ZM6 13.17L11.93 7.24L14.76 10.07L8.83 16H6V13.17ZM21 20H3C2.73478 20 2.48043 20.1054 2.29289 20.2929C2.10536 20.4804 2 20.7348 2 21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2929C21.5196 20.1054 21.2652 20 21 20Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="w-full md:flex-1">
                                    <div className="mb-4 text-xl md:text-2xl leading-tight text-white font-bold tracking-wider">Become a Validator</div>
                                    <p className="text-white text-sm leading-7 font-medium">There's something for everybody. Follow along, chat on Telegram, or read up on what we’re doing.</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap p-8 text-center md:text-left hover:bg-white hover:bg-opacity-10 rounded-md hover:shadow-xl transition duration-200">
                                <div className="w-full md:w-auto mb-6 md:mb-0 md:pr-6">
                                    <div className="inline-flex h-14 w-14 mx-auto items-center justify-center text-white bg-[#ff1d3f] rounded-lg">
                                        <svg width={21} height={21} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H10C10.2652 22 10.5196 21.8946 10.7071 21.7071C10.8946 21.5196 11 21.2652 11 21V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 20H4V15H9V20ZM21 2H14C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H21C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2V2ZM20 9H15V4H20V9ZM21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13ZM20 20H15V15H20V20ZM10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2V2ZM9 9H4V4H9V9Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="w-full md:flex-1">
                                    <div className="mb-4 text-xl md:text-2xl leading-tight text-white font-bold tracking-wider">Developer Resources</div>
                                    <p className="text-white text-sm leading-7 font-medium">There's something for everybody. Follow along, chat on Telegram, or read up on what we’re doing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative pb-6 bg-[#161343] overflow-hidden h-screen hidden">
                <div className="relative pt-6 md:pt-0">
                    <div className="container px-4 mx-auto">
                        <div className="relative flex flex-wrap items-center -mx-4">
                            <div className="relative z-10 w-full px-4 mb-6 md:mb-0 text-center">
                                <div className="mx-auto">
                                    <div className="mb-6 lg:mb-0 lg:mt-32 lg:-mr-0">
                                        <p className="lg:transform lg:rotate-90 tracking-widest text-lg text-white uppercase whitespace-nowrap lg:-mb-20"></p>
                                    </div>
                                    <div className='relative z-10'>
                                        <h2 className="mb-8 text-4xl lg:text-6xl text-white font-bold">
                                            <span className='text-lg lg:text-3xl block mb-5 text-[#3E349D]'>Decentralized finance with</span><span> Secure & Scalable Blockchain Network</span></h2>
                                        <a className="inline-block w-full md:w-auto mb-2 md:mb-0 py-5 px-8 mr-6 text-center text-sm font-bold uppercase bg-white hover:bg-[#7d0000] transition duration-200" href="#">JOIN IGT PRIVATE SALE</a>
                                        {/* <a className="inline-block w-full md:w-auto py-5 px-8 text-center text-sm text-white font-bold uppercase border border-white hover:border-gray-50 transition duration-200" href="#">Request demo</a> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="hidden md:block absolute top-0 right-0 w-full md:w-1/2 mr-10 px-4">
                                <img className="w-full h-80 lg:h-128 object-cover" src="bendis-assets/images/red-image.png" alt="" />
                                <LazyLoadImage src="logo.png" className="w-full h-80 lg:h-128 object-cover" />    
                            </div> */}
                        </div>
                    </div>
                </div>
                <video autoPlay muted loop className='mx-auto w-8/12 absolute bottom-0 left-1/2 transform -translate-x-1/2'>
                    <source src="fire-animation.mp4" type="video/mp4" />
                </video>
            </section>

            <div className="bg-indigo-900 relative overflow-hidden h-screen hidden">
                {/* <img src="https://www.tailwind-kit.com/images/landscape/5.svg" className="absolute h-full w-full object-cover" /> */}
                <LazyLoadImage src="https://www.tailwind-kit.com/images/landscape/5.svg" className="absolute h-full w-full object-cover" />
                <div className="inset-0 bg-black opacity-25 absolute">
                </div>
                <header className="absolute top-0 left-0 right-0 z-20">
                <nav className="container mx-auto px-6 md:px-12 py-4">
                    <div className="md:flex justify-center items-center">
                    <div className="flex justify-between items-center">
                        <div className="md:hidden">
                        <button className="text-white focus:outline-none">
                            <svg className="h-12 w-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                            </path>
                            </svg>
                        </button>
                        </div>
                    </div>
                    <div className="hidden md:flex items-center">
                        <a className="text-lg uppercase mx-3 text-white cursor-pointer hover:text-gray-300">
                        Ticket
                        </a>
                        <a className="text-lg uppercase mx-3 text-white cursor-pointer hover:text-gray-300">
                        Info
                        </a>
                        <a className="text-lg uppercase mx-3 text-white cursor-pointer hover:text-gray-300">
                        Contact
                        </a>
                    </div>
                    </div>
                </nav>
                </header>
                <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
                <div className="w-full flex flex-col items-center relative z-10">
                    <h1 className="font-extrabold text-7xl text-center sm:text-8xl text-white leading-tight mt-4">
                    Life-changing
                    </h1>
                    <a href="#" className="block bg-gray-800 hover:bg-gray-900 py-3 px-4 text-lg text-white font-bold uppercase mt-10">
                    Start Now
                    </a>
                </div>
                </div>
            </div>
        </>
    );
};
  
export default Home;