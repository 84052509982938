import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import Header from "./include/Header";

export const useScrollHandler = () => {
    const [scroll, setScroll] = useState(0);
  
    useEffect(() => {
      const onScroll = () => {
        const scrollCheck = window.scrollY > 500;
        setScroll(scrollCheck);
      };
  
      document.addEventListener("scroll", onScroll);
      return () => {
        document.removeEventListener("scroll", onScroll);
      };
    }, [scroll, setScroll]);

    console.log(scroll);
  
    return scroll;
};

const Layout = () => {  
    const scroll = useScrollHandler();
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };
  return (      
    <>
        {/* Header */}
        <nav className={`header w-full py-4 px-4 xl:px-10 xl:py-8 top-0 left-0 z-50 ${scroll ? "sticky active" : "bg-transparent relative"}`}>
            <div className="md:container mx-auto px-4">
                <div className="flex justify-between items-center">
                    <Link to="/" className="text-xl text-white font-semibold" href="#">
                        {/* <img className="h-7" src="logo.png" alt="" width="auto" /> */}
                        <div className="flex items-center">
                            <LazyLoadImage src="images/ignite-hero-logo.png" className="h-10 md:h-14 mr-4 animate-pulse" />
                            <LazyLoadImage src="images/logo-text.png" className="h-6" />
                        </div>
                    </Link>
                    <div className="xl:hidden">
                        <button className="navbar-burger flex items-center p-3 rounded" onClick={handleToggle}>
                            {/* <svg className="text-white block h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                            <title>Mobile menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg> */}
                            <svg  className="text-white block h-4 w-4" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 25 25" style={{enableBackground: 'new 0 0 25 25'}} xmlSpace="preserve">
                                <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.st0{fill:#EBEBEB;}\n\t.st1{fill:#E9E9E9;}\n" }} />
                                <path className="st0" d="M12.21,14.03c-3.23,0-6.46,0.02-9.69-0.01c-1.02-0.01-2.28,0.03-2.3-1.41c-0.02-1.45,1.23-1.45,2.26-1.45
                            c6.61-0.02,13.22-0.02,19.83,0c1.02,0,2.28-0.03,2.3,1.41c0.02,1.45-1.23,1.44-2.26,1.45C18.97,14.04,15.59,14.03,12.21,14.03z" />
                                <path className="st1" d="M6.88,3.02C5.2,3.01,3.51,3.05,1.83,3C0.97,2.97,0.21,2.63,0.2,1.6c-0.02-1.05,0.77-1.4,1.62-1.41
                            C5.17,0.15,8.54,0.14,11.9,0.18c0.92,0.01,1.81,0.32,1.74,1.51c-0.06,1.03-0.87,1.3-1.72,1.32C10.24,3.05,8.56,3.02,6.88,3.02z" />
                                <path className="st1" d="M18.07,22.14c1.61,0,3.22-0.02,4.83,0.01c0.95,0.02,1.81,0.32,1.8,1.46c-0.01,1.03-0.83,1.35-1.67,1.36
                            c-3.36,0.04-6.73,0.04-10.09,0c-0.84-0.01-1.67-0.3-1.69-1.34c-0.03-1.16,0.84-1.46,1.78-1.48C14.71,22.11,16.39,22.14,18.07,22.14z
                            " />
                            </svg>
                        </button>
                    </div>
                    <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 xl:flex lg:w-auto lg:space-x-10">
                        <li><Link to="/" className="text-white text-sm tracking-wider font-normal hover:text-[#ff1d3f]">Home</Link></li>
                        <li><Link to="/protocol" className="text-white text-sm tracking-wider font-normal hover:text-[#ff1d3f]">Protocol</Link></li>
                        <li><Link to="/explorer" className="text-white text-sm tracking-wider font-normal hover:text-[#ff1d3f]">Explorer</Link></li>
                        <li><Link to="/wallet" className="text-white text-sm tracking-wider font-normal hover:text-[#ff1d3f]">Wallet</Link></li>
                        <li><Link to="/whitepaper" className="text-white text-sm tracking-wider font-normal hover:text-[#ff1d3f]">Whitepaper</Link></li>
                        <li><Link to="/developer" className="text-white text-sm tracking-wider font-normal hover:text-[#ff1d3f]">Developer</Link></li>
                        <li><Link to="/contact" className="text-white text-sm tracking-wider font-normal hover:text-[#ff1d3f]">Contact</Link></li>
                    </ul>
                    <div className="hidden xl:block">
                        {/* <a className="inline-block px-8 text-white hover:text-blue-50 font-semibold" href="#">Sign in</a> */}
                        <a className="inline-block py-3 px-8 text-sm text-white uppercase font-semibold border-2 border-white hover:border-[#7A092A] hover:bg-[#7A092A] shadow-2xl" href="#">Connect Wallet</a>
                    </div>
                </div>
            </div>
        </nav>
        
        <div className={`xl:hidden navbar-menu fixed top-0 left-0 bottom-0 w-full max-w-full z-50 ${isActive ? "hidden" : "block"}`}>
            {/* <div className="navbar-backdrop fixed inset-0 bg-blue-800 opacity-90" /> */}
            <nav className="relative flex flex-col justify-start py-8 w-full h-full bg-[#0D0620] overflow-y-auto">
                <div className="flex items-center justify-between mb-8 pr-6">
                    <a className="ml-5 mr-auto text-xl text-blue-800 font-semibold leading-none" href="#">
                    <div className="flex items-center">
                            <LazyLoadImage src="images/ignite-hero-logo.png" className="h-10 md:h-14 mr-4 animate-pulse" />
                            <LazyLoadImage src="images/logo-text.png" className="h-6" />
                        </div>
                    </a>
                    <div className="close text-white text-xl" onClick={handleToggle}>
                        <i class="las la-times"></i>
                    </div>
                </div>
                <div>
                    <ul>
                        <li className="mb-1"><Link to="/" className="block pl-5 py-3 text-white text-sm tracking-wider font-normal hover:text-[#7A092A]" href="#">About</Link></li>
                        <li className="mb-1"><Link to="/protocol" className="block pl-5 py-3 text-white text-sm tracking-wider font-normal hover:text-[#7A092A]" href="#">Protocol</Link></li>
                        <li className="mb-1"><Link to="/explorer" className="block pl-5 py-3 text-white text-sm tracking-wider font-normal hover:text-[#7A092A]" href="#">Explorer</Link></li>
                        <li className="mb-1"><Link to="/wallet" className="block pl-5 py-3 text-white text-sm tracking-wider font-normal hover:text-[#7A092A]" href="#">Wallet</Link></li>
                        <li className="mb-1"><Link to="/whitepaper" className="block pl-5 py-3 text-white text-sm tracking-wider font-normal hover:text-[#7A092A]" href="#">Whitepaper</Link></li>
                        <li className="mb-1"><Link to="/developer" className="block pl-5 py-3 text-white text-sm tracking-wider font-normal hover:text-[#7A092A]" href="#">Developer</Link></li>
                        <li className="mb-1"><Link to="/contact" className="block pl-5 py-3 text-white text-sm tracking-wider font-normal hover:text-[#7A092A]" href="#">Contact</Link></li>
                    </ul>
                </div>
                <div className="mt-auto px-5">
                    <div className="pt-6">
                        {/* <a className="block mb-2 py-4 text-center text-blue-800 font-semibold" href="#">Sign in</a> */}
                        <a className="block py-3 px-8 text-center text-sm bg-[#7A092A] hover:bg-white text-white hover:text-[#7A092A] uppercase font-semibold" href="#">Connect Wallet</a>
                    </div>
                </div>
            </nav>
        </div>
        {/* Header */}
        {/* Content */}
        <Outlet />
        {/* Content */}
        {/* Footer */}
        <section className="bg-black bg-opacity-5 overflow-x-hidden">
            <div className="pt-8 md:pt-20 border-t border-opacity-20 border-white">
                <div className="pb-8 md:pb-20 border-b border-opacity-10 border-white">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full md:w-2/5 px-4 mb-6 md:mb-0">
                            <div className="relative flex mb-5">
                                <div className="flex items-center">
                                    <LazyLoadImage src="images/ignite-hero-logo.png" className="h-10 md:h-14 mr-4 animate-pulse" />
                                    <LazyLoadImage src="images/logo-text.png" className="h-6" />
                                </div>
                            </div>
                            <div className="max-w-sm">
                                <p className="mb-4 text-sm text-white leading-8">Experience the ultra fast, low cost & highly scalable blockchain network with Ignite Ecosystem.</p>
                            </div>
                            </div>
                            <div className="w-full md:w-3/5 px-4">
                                <div className="flex flex-wrap -mx-4">
                                    <div className="w-1/2 md:w-1/3 px-4 mb-12 md:mb-0">
                                        <h3 className="mb-8 text-lg text-white font-normal uppercase">Ignite Tool</h3>
                                        <ul>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Wallet</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Explorer</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Documentation</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Testnet</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Mainnet</a></li>
                                        </ul>
                                    </div>
                                    <div className="w-1/2 md:w-1/3 px-4 mb-12 md:mb-0">
                                        <h3 className="mb-8 text-lg text-white font-normal uppercase">Ecosystem</h3>
                                        <ul>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">About us</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Media and Press release</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Roadmap</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Submit DAPPs</a></li>
                                        </ul>
                                    </div>
                                    <div className="w-1/2 md:w-1/3 px-4">
                                        <h3 className="mb-8 text-lg text-white font-normal uppercase">Developer</h3>
                                        <ul>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Whitepaper</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Dev Documents</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Node setup process</a></li>
                                            <li className="mb-4 last:mb-0"><a className="text-sm text-white hover:text-[#ff1d3f]" href="#">Devforum</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 py-6">
                    <div className="flex flex-wrap md:justify-between justify-center">
                        {/* <a className="inline-block text-xl text-white font-medium font-heading mb-8 md:mb-0" href="#">
                            <LazyLoadImage src="images/ignite-hero-logo.png" className="h-10 md:h-14 mr-4 animate-pulse" />
                        </a> */}
                        <p className="mb-4 md:mb-0 text-sm text-gray-200">All rights reserved © Ignite {new Date().getFullYear()}.</p>
                        <div className="w-full md:w-auto">
                            <div className="flex flex-wrap md:justify-end justify-center">
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-facebook-f"></i>
                                </a>
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-twitter"></i>
                                </a>
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-instagram"></i>
                                </a>
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-medium"></i>
                                </a>
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-reddit"></i>
                                </a>
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-youtube"></i>
                                </a>
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-telegram"></i>
                                </a>
                                <a className="mr-5 last:mr-0 text-white hover:text-[#ff1d3f] text-xl" href="#">
                                    <i className="lab la-github"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Footer */}
    </>
  )
};

export default Layout;